import React from "react";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        <header>
          <div className="page_width rel">
            <nav>
              <a href="/" id="logo" />
              <span id="menu_btn" />
              <ul id="menu">
                <li>
                  <a href="/">Hlavná stránka</a>
                </li>
                <li>
                  <a href="/lieky/">Lieky</a>
                </li>
                <li>
                  <a href="/radca/">Radca</a>
                </li>
                <li>
                  <a href="/upravte_liek/">Test</a>
                </li>
                <li>
                  <a href="/kontakt/">Kontakt</a>
                </li>
                <li>
                  <a href="/otazky_a_odpovede/">Otázky a odpovede</a>
                </li>
                <li>
                  <a href="/kde_kupit/">KDE KÚPIŤ </a>
                </li>
              </ul>
            </nav>
          </div>
        </header>
        <div id="page" className="page_width">
          <div id="breadcrumbs">
            <a href="/">Hlavná stránka</a>
            <a href="/radca/#deti"> &gt; Radca(DETI)</a>
            <a href="/radca/deti/ako_naucit_dieta_siakat/" className="sel">
              {" "}
              &gt; Ako naučiť dieťa siakať?
            </a>
          </div>
          <div id="page_content">
            <div className="col1">
              <div className="main_photo">
                <img src="/web/kcfinder/uploads/files/artykuly/dzieci_poradnik_03.jpg" />
              </div>
            </div>
            <div className="col2">
              <h1>Ako naučiť dieťa siakať?</h1>
              <div className="wysiwyg">
                <div>
                  <strong>
                    Keď zistíte, že Vaše dieťa má nádchu, nezabudnite, že malé
                    deti väčšinou nevedia siakať. A to je problém, pretože deti
                    nevedia dýchať ústami a naučia sa to až neskôr. Svoju
                    ratolesť by ste preto mali naučiť siakať čo najskôr. Samotné
                    siakanie je vlastne dlhý výdych nosom, pusa je pri tom
                    samozrejme zatvorená. Správne sa musí siakať najprv jednou a
                    potom druhou nosovou dierkou. Inak hrozí zápal stredného
                    ucha.
                  </strong>
                </div>
                <div>&nbsp;</div>
                <div>
                  <strong>
                    <img
                      alt="Inštrukcie pre rodičov ako naučiť svoje deti smrkať."
                      src="/web/kcfinder/uploads/images/sk/dzieci_poradnik_01.jpg"
                      style={{
                        width: "380px",
                        height: "252px",
                        "border-width": "2px",
                        "border-style": "solid",
                        margin: "5px 10px",
                        float: "right"
                      }}
                    />
                  </strong>
                </div>
                <div>
                  <div>
                    <strong>Ako naučiť dieťa siakať:</strong>
                  </div>
                  <div>&nbsp;</div>
                  <ul>
                    <li>
                      <strong>Predvádzajte zviera!</strong> Naučte dieťa „ako
                      robí ježko“ a pokojne spolu s ním napodobňujte siakanie
                      ježkovho ňufáčika. Potom už iba stačí, napríklad aj pri
                      menšej nádche, priložiť vreckovku dieťaťu k nosu a
                      povedať, nech urobí ježka. Samozrejme môžete použiť aj
                      fňukanie králika, trúbenie jeleňa alebo slona a pod. Aby
                      ste sa uistili, že dieťa bude ochotné spolupracovať,
                      použite vreckovky so zvieratkami.
                    </li>
                    <li>
                      <strong>Fúkajte do balónika nosom!</strong> Položte
                      balónik na stôl a dieťaťu povedzte, nech do neho fúkne
                      nosom. Zažijete spolu veľa zábavy a dieťa sa naučí
                      rýchlejšie siakať.
                    </li>
                    <li>
                      <strong>Choďte príkladom! </strong>Je známe, že deti radi
                      napodobňujú svojich rovesníkov, ale aj dospelých. V škôlke
                      sa tak môžu učiť siakať od svojich kamarátov a doma to
                      odpozorujú od vás.
                    </li>
                    <li>
                      <strong>Vyfukujte bubliny!</strong> Až bude dieťa vo vani,
                      naučte ho fúkať bubliny do vody.
                    </li>
                    <li>
                      <strong>Sfúkavajte sviečky nosom!</strong> V záujme
                      bezpečnosti použite radšej malé narodeninové sviečky.
                    </li>
                    <li>
                      <strong>Fúkajte nosom do zrkadla!</strong> Deťom sa páči
                      zahmlený efekt na zrkadlovej ploche.
                    </li>
                    <li>
                      <strong>Odfukujte papierové guľôčky na stole!</strong>{" "}
                      Môžete si zahrať aj hru, kto odfúkne guľôčku do väčšej
                      vzdialenosti.
                    </li>
                  </ul>
                  <div>&nbsp;</div>
                </div>
                <p>&nbsp;</p>
              </div>
              <div className="other_article">
                <p className="name">Ďalšie články</p>
                <a href="/radca/deti/upchaty_nos_u_detC3AD/" className="box">
                  <span className="photo" style={{
                    backgroundImage: "url(/web/kcfinder/uploads/files/pokonaj_zatkany_nos-_dziecka_szybko_skutecznie.png)"
                  }} />
                  <span className="title">Upchatý nos u detí</span>
                </a>
                <a
                  href="/radca/deti/imunita_dietata_dbajte_a_posilnujte/"
                  className="box"
                >
                  <span className="photo" style={{ backgroundImage: "url(/web/kcfinder/uploads/files/iStock_63844397_LARGE.png)" }} />
                  <span className="title">
                    Imunita dieťaťa – dbajte a posilňujte
                  </span>
                </a>
                <a
                  href="/radca/deti/Boj_s_infekciami_nadcha_a_kasel_u_dietata/"
                  className="box"
                >
                  <span className="photo" style={{
                    backgroundImage: "url(/web/kcfinder/uploads/files/walka_infekcjami_katar-_kaszel-_dziecka.png)"
                  }} />
                  <span className="title">
                    Boj s infekciami – nádcha a kašeľ u dieťaťa
                  </span>
                </a>
              </div>
            </div>
            <div className="clear" />
          </div>{" "}
        </div>
        <Footer></Footer>{" "}

      </div >
    );
  }
}

export default Page;
